.cwt-page-header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: @layout-header-background;
    z-index: 1;
}

.cwt-page-header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0px 8px;
    min-height: 48px;

    .cwt-page-header-left,
    .cwt-page-header-right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .cwt-page-header-middle {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.cwt-page-header .cwt-page-header-middle {
    font-size: 1.25rem;
    font-weight: 500;
}
.cwt-page-header .cwt-page-header-subtitle {
    color: @primary-color;
}