.ant-table {
    background-color: transparent !important;
    overflow: auto;
}


.ant-table-content > table {
    border-spacing: 0px 0.5rem;
    table-layout: fixed;
}

.ant-table-thead > tr > th {
    background-color: transparent !important;
    border: none;
    padding: 0.5rem 0.5rem;
}

.ant-table-thead > tr > th::before {
    display: none;
}

.ant-table-tbody > tr > td {
    background-color: white;
    padding: 1rem 0.5rem;
    border-bottom: none;

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 1rem;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 1rem;
    }
}


.ant-table-thead > tr > th {
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 1rem;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-right: 1rem;
    }
}

.ant-table-cell-fix-right { right: 0; }
.ant-table-cell-fix-left { left: 0; }


nz-table {
    .ant-table-tbody > tr {
        > td {
            transition: border-color 0.3s ease;
            border: 1px solid @border-color-split;
            &:not(:first-child) { border-left: none; }
            &:not(:last-child) { border-right: none; }
        }

        &.selected {
            > td {
                border-color: @primary-color;
            }
        }
    }
}