@import '../../../../../../node_modules/quill/dist/quill.snow.css';

@quill-size: @font-size-base * 1.666667;

quill-editor {
    --editor-disabled-background: @input-disabled-bg;
    --editor-radius: @border-radius-base;
    --editor-border-color: @border-color-base;
    --editor-border-width: 1px;

    border-radius: var(--editor-radius);
    transition: all 0.3s @ease-in-out, height 0ms;
    border-color: var(--editor-border-color) !important;
    
    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    &[borderless] {
        --editor-radius: 0px;
        --editor-border-width: 0px;
        --editor-disabled-background: transparent;
    }
}

.ql-snow.ql-toolbar {
    padding: 4px;
    border-top-left-radius: var(--editor-radius);
    border-top-right-radius: var(--editor-radius);
    border-width: var(--editor-border-width);
}

.ql-snow.ql-container {
    border-bottom-left-radius: var(--editor-radius);
    border-bottom-right-radius: var(--editor-radius);
    border-width: var(--editor-border-width);
    display: flex;
    align-items: stretch;
    flex-direction: row;
    overflow: hidden;
    flex: 1;
    min-height: 0px;
}

.ql-snow.ql-toolbar,
.ql-container,
.ql-toolbar,
.ql-snow.ql-container {
    border-color: var(--editor-border-color) !important;
    transition: border-color 0.3s @ease-in-out;
}


.ql-snow.ql-container .ql-editor {
    padding: 8px;
    height: initial;
    flex: 1;
}

.ql-snow.ql-toolbar button, 
.ql-snow .ql-toolbar button {
    width: @quill-size;
    height: @quill-size;
    padding: 2px;
}

.ql-snow.ql-toolbar .ql-formats {
    margin-right: 4px;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
    width: @quill-size;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
    padding: 2px;
    border: none !important;
}

.ql-snow .ql-picker {
    height: @quill-size;
    font-size: @font-size-base;
}

.ql-snow .ql-picker-label {
    padding-left: 2px;
    padding-right: 2px;
}

.ql-snow .ql-picker-label::before {
    line-height: @font-size-base;
}

quill-editor.fill-card {
    height: 100%;
    display: flex !important;
    flex-direction: column;

    > .ql-toolbar {
        flex-shrink: 0;
    }

    > .ql-container {
        flex: 1;
    }
}

quill-editor.ng-touched.ng-invalid {
    > .ql-toolbar,
    > .ql-container {
        border-color: @error-color !important;
    }
}

quill-editor[disabled] {
    background-color: var(--editor-disabled-background);
    color: @input-disabled-color;
    cursor: not-allowed;

    .ql-toolbar {
        pointer-events: none;
    }
}

.ql-editor.ql-blank::before {
    color: @input-placeholder-color;
    font-style: normal;
    left: 8px;
    right: 8px;
}