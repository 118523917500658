.ant-card {
    > .ant-card-body {
        padding: 1rem;
    }
    &.flex-card {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        > *:not(.ant-card-body) {
            flex-shrink: 0;
        }
    
        > .ant-card-body {
            flex: 1;
            min-height: 0;
        }
    }

    &.compact-card {
        > .ant-card-body {
            padding: 0.5rem 1rem;
        }
    }

    &.scroll-card {
        overflow: hidden;
        > .ant-card-body {
            overflow-y: auto;
        }
    }
}