
.ant-popover-inner-content {
    .ant-menu.ant-menu-vertical { margin: 0 @popover-padding-horizontal * -1; }
    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right
    .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title { padding: 0 @popover-padding-horizontal; }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border: none !important;
}

.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-dark.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid @primary-color-hover;
}
.ant-popover.cwt-popover-no-padding .ant-popover-inner-content { padding: 0; }
.ant-popover.cwt-popover-overflow-hidden .ant-popover-inner { overflow: hidden; }
.ant-popover.cwt-popover-no-arrow .ant-popover-arrow { display: none; }

.ant-menu-inline.cwt-menu-rounded > .ant-menu-item,
.ant-menu-vertical.cwt-menu-rounded > .ant-menu-item {
    border-radius: @border-radius-base;
}


.ant-dropdown-menu.ant-dropdown-menu-root {
    .ant-dropdown-menu-item {
        gap: 0.5rem;
    }
}